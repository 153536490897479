import {gql} from "@apollo/client"
import {
    CONCEPT_BASE_INFO,
    CONCEPT_PROP_INFO,
    CONCEPT_PROP_VALUE_TYPE_INFO,
    CONCEPT_PROP_VALUE_TYPE_INLINE,
    CONCEPT_SHORT_INFO,
    DATE_TIME_FULL,
    DOC_METRIC_INFO,
    DOCUMENT_CONCEPTS_INFO,
    DOCUMENT_INFO,
    DOCUMENT_META_INFO, DOCUMENT_STAT_INFO,
    EVENT_PROP_INFO,
    METRIC_INFO,
    PROPERTY_VALUE_INFO,
    REL_EXT_MODEL_INFO,
} from "./fragments";

export const CONCEPT_META = gql`
    query ConceptMeta($id: ID!){
        concept(id: $id){
            id
            name
            image {
              url
              thumbnail
            }
            metric{
                ...MetricInfo
            }
            paginationConceptProperty(limit:50, filterSettings:{}){
                total
                listConceptProperty{
                    ...ConceptPropertyInfo
                    __typename
                }
            }
        }
    }
    ${PROPERTY_VALUE_INFO}
    ${CONCEPT_PROP_VALUE_TYPE_INFO}
    ${CONCEPT_PROP_VALUE_TYPE_INLINE}
    ${METRIC_INFO}
    ${CONCEPT_PROP_INFO}
    ${DATE_TIME_FULL}
`
export const CONCEPT_LIST_META = gql`
    query ConceptListMeta($ids: [ID!]!, $linksType: [ID!]){
        listConceptById(ids: $ids){
            ...ConceptShortInfo
            paginationConceptProperty(limit:50, filterSettings:{}){
                total
                listConceptProperty{
                    ...ConceptPropertyInfo
                    __typename
                }
            }
            paginationConceptLink(filterSettings: {conceptLinkType: $linksType, isEvent: false}, limit: 2000){
              total
              listConceptLink{
                paginationDocument{
                  total
                }
                conceptFrom{
                  id
                  name
                  metric{
                    ...MetricInfo
                  }
                }
                conceptTo{
                  id
                  name
                  metric{
                    ...MetricInfo
                  }
                }
                conceptLinkType{
                  id
                  name
                  isDirected
                }
              }
            }
        }
    }
    ${CONCEPT_SHORT_INFO}
    ${PROPERTY_VALUE_INFO}
    ${CONCEPT_PROP_VALUE_TYPE_INFO}
    ${CONCEPT_PROP_VALUE_TYPE_INLINE}
    ${CONCEPT_PROP_INFO}
    ${DATE_TIME_FULL}
    ${REL_EXT_MODEL_INFO}
`

export const CONCEPT_DOSSIER = gql`
    query ConceptDossier($id: ID!){
        concept(id: $id){
            ...ConceptShortInfo
            paginationConceptProperty(limit:50, filterSettings:{}){
                total
                listConceptProperty{
                    ...ConceptPropertyInfo
                    __typename
                }
            }
            paginationConceptLink(filterSettings: {}, limit: 1000){
                total
                listConceptLink{
                    id
                    notes
                    startDate{
                        ...DateTimeFull
                    }
                    endDate{
                        ...DateTimeFull
                    }
                    conceptFrom{
                        ...ConceptShortInfo
                    }
                    conceptTo{
                        ...ConceptShortInfo
                    }
                    paginationDocument{
                        total
                    }
                    conceptLinkType{
                        id
                        name
                    }
                }
            }
        }
        paginationKBRelatedDocument(id: $id, filterSettings: {}, limit: 5){
            total
            listDocument{
                ...DocumentConceptsInfo
            }
        }
    }
    ${CONCEPT_SHORT_INFO}
    
    ${METRIC_INFO}
    
    ${DOCUMENT_CONCEPTS_INFO}
    
    ${CONCEPT_SHORT_INFO}
    
    ${CONCEPT_PROP_INFO}

    ${CONCEPT_PROP_VALUE_TYPE_INLINE}
    
    ${REL_EXT_MODEL_INFO}

    ${PROPERTY_VALUE_INFO}
    
    ${DATE_TIME_FULL}
`

export const CONCEPT_RELATED_DOCUMENTS_PAGE = gql`
    query ConceptRelatedDocuments($id:ID!, $limit: Int, $offset: Int){
        paginationKBRelatedDocument(id: $id, filterSettings: {}, limit: $limit, offset: $offset){
            total
            listDocument{
                ...DocumentConceptsInfo
            }
        }
    }
    ${DOCUMENT_CONCEPTS_INFO}
`

export const CONCEPTS_SHORT_INFO = gql`
    query ConceptsShortInfo($ids: [ID!]!){
        listConceptById(ids: $ids){
            ...ConceptShortInfo
        }
    }
    ${CONCEPT_SHORT_INFO}
`

export const CONNECTIONS_TYPES = gql`
    query ConnectionsType($conceptFromType: ID, $conceptToType: ID){
        paginationConceptLinkType(filterSettings:{conceptFromTypeId: $conceptFromType, conceptToTypeId: $conceptToType}, limit: 100){
            total
            listConceptLinkType{
                id
                name
                conceptFromType{
                  id
                  name
                }
                conceptToType{
                  id
                  name
                }
            }
        }
    }
`

export const CONCEPT_IN_SEARCH = gql`
    query ConceptInSearch($id: ID!){
        paginationSearchObjects(filterSettings:{conceptId: $id}, limit: 5, offset: 0, sorting:{sort: ChangedAt, direction: descending}){
            total
            listSearchObject{
                id
                name
                target
            }
        }
    }
`

export const CONCEPT_LINKS = gql`
    query ConceptLinks($id: ID!, $isEvent: Boolean = false, $limit: Int = 50){
        concept(id:$id){
            paginationConceptLink(filterSettings: {isEvent: $isEvent}, limit: $limit){
                total
                listConceptLink{
                    id
                    startDate{
                        ...DateTimeFull
                    }
                    endDate{
                        ...DateTimeFull
                    }
                    conceptFrom{
                        ...ConceptBaseInfo
                    }
                    conceptTo{
                        ...ConceptBaseInfo
                    }
                    conceptLinkType{
                        id
                        name
                    }
                    paginationDocument{
                        total
                    }
                }
            }
        }
    }
    
    ${CONCEPT_BASE_INFO}
    ${DATE_TIME_FULL}
`
export const CONCEPT_EVENTS_LINKS = gql`
    query ConceptEventsLinks($id: ID!, $limit: Int = 50){
        concept(id:$id){
            paginationConceptLink(filterSettings: {isEvent: true}, limit: $limit){
                total
                listConceptLink{
                    id
                    startDate{
                        ...DateTimeFull
                    }
                    endDate{
                        ...DateTimeFull
                    }
                    conceptFrom{
                        ...ConceptShortInfo
                        paginationConceptProperty(limit:50, filterSettings:{}){
                            total
                            listConceptProperty{
                                ...ConceptPropertyInfo
                                __typename
                            }
                        }
                    }
                    conceptTo{
                        ...ConceptShortInfo
                        paginationConceptProperty(limit:50, filterSettings:{}){
                            total
                            listConceptProperty{
                                ...ConceptPropertyInfo
                                __typename
                            }
                        }
                    }
                    conceptLinkType{
                        id
                        name
                    }
                    paginationDocument{
                        total
                    }
                }
            }
        }
    }
    
    ${CONCEPT_SHORT_INFO}
    ${CONCEPT_PROP_VALUE_TYPE_INLINE}
    ${CONCEPT_PROP_INFO}
    ${PROPERTY_VALUE_INFO}
    ${DATE_TIME_FULL}
`

export const CONCEPT_DOCS_PAGE = gql`
    query ConceptDocsPage($id: ID!, $limit: Int, $offset: Int){
        paginationKBRelatedDocument(filterSettings: {documentContentType: text}, id: $id, limit: $limit, offset: $offset){
            total
            listDocument{
                ...DocumentConceptsInfo
            }
        }
    }
    ${DOCUMENT_CONCEPTS_INFO}
`
export const CONCEPT_DOCS_STAT_PAGE = gql`
    query ConceptDocsStatPage($id: ID!, $limit: Int, $offset: Int){
        paginationKBRelatedDocument(filterSettings: {documentContentType: text}, id: $id, limit: $limit, offset: $offset){
            total
            listDocument{
                ...DocumentStatInfo
            }
        }
    }
    ${DOCUMENT_STAT_INFO}
`

export const CONCEPT_DOCS_PAGE_DATES_FILTER = gql`
    query ConceptDocsPageByDates($id: ID!, $limit: Int, $offset: Int, $date_start: UnixTime, $date_end: UnixTime){
        paginationKBRelatedDocument(filterSettings: {documentContentType: text, publicationDate:{start: $date_start, end: $date_end}}, id: $id, limit: $limit, offset: $offset){
            total
            listDocument{
                ...DocumentStatInfo
            }
        }
    }
    ${DOCUMENT_STAT_INFO}
`

export const CONCEPT_STORIES_COUNT = gql`
    query ConceptStoriesCount($q: String){
        paginationStory(filterSettings: {searchString: $q}, extraSettings:{}, limit: 1){
            total
            showTotal
        }
    }
    `

export const CONCEPT_DOCS_COUNT = gql`
    query ConceptStoriesCount($concept: ID!, $filter: RelatedDocumentFilterSettings! = {}){
      paginationKBRelatedDocument(filterSettings: $filter, id: $concept, limit: 0){
        total
      }
    }
`

export const CONCEPTS_LIST_STORIES = gql`
    query ConceptListStories($ids: [ID!]){
        paginationStory(filterSettings: {concepts: $ids, childVisibility: all}, limit: 1000,extraSettings:{}){
        total
        showTotal
        listStory{
          id
          main{
            metadata{
              platform{
                id
                name
                url
              }
            }
          }
        }
      }
    }
`

export const STORIOES_STATICTIC = gql`
query StoriesStat($q: String,$date0: UnixTime, $date1: UnixTime, $date2: UnixTime, $date3: UnixTime, $date4: UnixTime, $date5: UnixTime, ,$date6: UnixTime, $date7: UnixTime, $date8: UnixTime, $date9: UnixTime, $date10: UnixTime, $date11: UnixTime, $date12: UnixTime){
        DocsDate_0: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date1, end: $date0}}){
            showTotal
        }
        DocsDate_1: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date2, end: $date1}}){
            showTotal
        }
        DocsDate_2: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date3, end: $date2}}){
            showTotal
        }
        DocsDate_3: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date4, end: $date3}}){
            showTotal
        }
        DocsDate_4: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date5, end: $date4}}){
            showTotal
        }
        DocsDate_5: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date6, end: $date5}}){
            showTotal
        }
        DocsDate_6: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date7, end: $date6}}){
            showTotal
        }
        DocsDate_7: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date8, end: $date7}}){
            showTotal
        }
        DocsDate_8: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date9, end: $date8}}){
            showTotal
        }
        DocsDate_9: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date10, end: $date9}}){
            showTotal
        }
        DocsDate_10: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date11, end: $date10}}){
            showTotal
        }
        DocsDate_11: paginationStory(limit: 1, extraSettings:{}, filterSettings:{searchString: $q, publicationDate: {start: $date12, end: $date11}}){
            showTotal
        }
    }
`

export const CONCEPT_STORIES_STATISTIC = gql`
    query ConceptDocsPage($id: ID!,$date0: UnixTime, $date1: UnixTime, $date2: UnixTime, $date3: UnixTime, $date4: UnixTime, $date5: UnixTime, ,$date6: UnixTime, $date7: UnixTime, $date8: UnixTime, $date9: UnixTime, $date10: UnixTime, $date11: UnixTime, $date12: UnixTime){
        DocsDate_0: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date1, end: $date0}}){
            total
        }
        DocsDate_1: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date2, end: $date1}}){
            total
        }
        DocsDate_2: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date3, end: $date2}}){
            total
        }
        DocsDate_3: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date4, end: $date3}}){
            total
        }
        DocsDate_4: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date5, end: $date4}}){
            total
        }
        DocsDate_5: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date6, end: $date5}}){
            total
        }
        DocsDate_6: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date7, end: $date6}}){
            total
        }
        DocsDate_7: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date8, end: $date7}}){
            total
        }
        DocsDate_8: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date9, end: $date8}}){
            total
        }
        DocsDate_9: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date10, end: $date9}}){
            total
        }
        DocsDate_10: paginationKBRelatedDocument(id:$id, limit: 1, filterSettings:{documentContentType: text, publicationDate: {start: $date11, end: $date10}}){
            total
        }
        DocsDate_11: paginationKBRelatedDocument(limit: 1, id:$id, filterSettings:{documentContentType: text, publicationDate: {start: $date12, end: $date11}}){
            total
        }
    }
`

export const CONCEPT_TYPES = gql`
query conceptTypes {
  paginationConceptType(limit: 100, filterSettings:{}){
    listConceptType{
      id
      name
      isEvent
    }
  }
}`

export const OBJECT_CONCEPT_TYPES = gql`
query conceptTypes {
  paginationConceptType(limit: 100, filterSettings:{isEvent: false}){
    listConceptType{
      id
      name
      isEvent
    }
  }
}`