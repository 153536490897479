import React, {useState, useEffect, useMemo} from 'react';
import {HandySvg} from "handy-svg";
import {analyticsBACK} from "../../ApiEndpoints";
import {STORIES_BY_QUERY_AND_PLATFORMS} from "../../graphql/streamQueries";
import Modal from "../../components/ui/Modal";
import Skeleton from "react-loading-skeleton";
import ico_doc from "../../ico/doc.svg";
import {BACK_HOST} from "../../Consts";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import {setStreamList} from "../../reducers/streamsSlice";
import {setHistory} from "../../reducers/userSlice";
import {isArray} from "@apollo/client/cache/inmemory/helpers";
import convertSVGtoImg from "../../functions/convertSVGtoImg";
import "../../styles/pages/streams.sass"
import {parseTQLQuery} from "../../functions/helpers";
import StreamFacet from "../../components/facets/StreamFacet";
import {parseText} from "../../functions/parseText";

const QueryDetailLine = React.lazy(() => import("../../components/ui/reports/QueryDetailLine"))
const TextStat = React.lazy(() => import("../../components/stream/TextStat"))
const ConceptDocs = React.lazy(() => import("../../components/concepts/dossier/ConceptDocs"))
const StreamDetailSource = React.lazy(() => import("../../components/stream/StreamDetailSource"))
const PlatformsOnStream = React.lazy(() => import("../../components/platforms/PlatformsOnStream"))

function PageStreamDetail({save = true}) {
	const [modalRename, setModalRename] = useState(false)
	const user = useSelector(state => state.user.value)
	const dispatch = useDispatch()
	const [search, setSearch] = useSearchParams();
	const [sourcesArray, setSourcesArray] = useState((search.get('sources'))? search.get('sources').split(',') : [])
	const [namedEntities, setNamedEntities] = useState((search.get('namedEntities'))? search.get('namedEntities') : null)
	const [conceptsInQuery, setConceptsInQuery] = useState((search.get('concepts'))? search.get('concepts') : null)
	const [searchQuery, setSearchQuery] = useState((search.get('q'))? JSON.parse(search.get('q')) : false)
	const [qString, setQString] = useState(false)

	const [streamName, setStreamName] = useState ('Название еще не введено')
	const [checkboxes, setCheckboxes] = useState({
		streamMeta: true,
		streamComment: true
	});
	const [docsPage, setDocsPage] = useState(false)
	const [docsTotal, setDocsTotal] = useState(false)
	const [streamComment, setStreamComment] = useState()
	const [materialCheckboxes, setMaterialCheckboxes] = useState({})
	const [modalOpened, setModalOpened] = useState(false);
	const [fileCreating, setFileCreating] = useState(false)
	const [reportDownloadLink, setReportDownloadLink] = useState(false)
	const [processing, setProcessing] = useState()
	const query = useLocation().search
	const [tmpStreamID, setTmpStreamID] = useState()
	const [docsLoadedList, setDocsLoadedList] = useState({})
	const [queryConcept, setQueryConcept] = useState({})
	const [downloadMode, setDownloadMode] = useState('file')//режим скачивания файла (файл [file] или воянтулз [voyant])
	const [storedCount, setStoredCount] = useState(0)

	useEffect(() => {
		document.title = 'Мгимо аналитика: лента ' + ((streamName === 'Название еще не введено')? '' : streamName)
	}, [streamName])

	useEffect(() => {//парсим поисковый запрос
		if(searchQuery.length > 0){
			const [tmp, concepts] = parseTQLQuery(searchQuery)
			if(concepts.length > 0){
				if(user.group.id === 1 || user.group.id === 2){
					setQueryConcept({meaningConceptCandidates: concepts})
				}else{
					setQueryConcept({meaningConceptCandidates: concepts, concepts: concepts})
				}
			}
			setQString(tmp)
		}
	},[searchQuery])

	useEffect(() => {
		// забираем название и описание стрима с бэка
		if(search.get('from_saved')){
			analyticsBACK.get('/stream/meta', {
				params:{
					userID: user.id,
					streamID: search.get('from_saved')
				}
			})
				.then((resp) => {
					if(resp.data.name)
						setStreamName(resp.data.name)
					if(resp.data.comment)
						setStreamComment(resp.data.comment)
					if(resp.data.documents_count && storedCount === 0)
						setStoredCount((prevState) => {
							return resp.data.documents_count
						})
				})
				.catch((resp) => {
					console.error('STREAM_META_FETCHING')
				})
		}

		// добавляем в историю новое событие
		analyticsBACK.post('/history', {
			userID: user.id,
			link: '/stream/content' + query,
			title:  ((streamName === 'Название еще не введено')?
				((save)? 'Лента ' : 'Поиск ' ) +
				searchQuery.map(query => {return query.text}).join(' ') : streamName)
		})
			.then(resp => {
				if(resp.data)
					dispatch(setHistory(resp.data))
			})
			.catch(resp => {
				console.error('Ошибка добавления в историю')
			})

		//устанавливаем, что стрим просмотрен (обновляем счетчик просмотренных документов)
		if(search.get('from_saved')){
			analyticsBACK.post('/stream/set_shown', {
				userID: user.id,
				streamID: search.get('from_saved')
			})
				.then(resp => {
					if(isArray(resp.data))
						dispatch(setStreamList(resp.data))
					let updatesCount = 0
					resp.data.forEach(stream => {
						if(stream.updated){
							updatesCount += 1
						}
					})
				})
		}
	},[])

	useEffect( () => {// устанавливаем счетчик документов
		if(docsPage === 'fail')
			setDocsTotal('fail')

		if(docsPage) {
			setDocsLoadedList({...docsLoadedList, ...docsPage})
		}
	}, [docsPage])

	const renameStream = (e) => {// переименовываем стрим
		e.preventDefault()
		const newName = e.target.querySelector('textarea[name="stream-name"]').value
		setProcessing(true)
		if(newName) {
			if(search.get('from_saved')) {//если стрим сохранен, то делаем запрос на переименование в бэк
				analyticsBACK.post('/stream/rename', {
					userID: user.id,
					streamID: (search.get('from_saved')) ?? tmpStreamID,
					name: newName
				})
					.then((resp) => {
						dispatch(setStreamList(resp.data))
						setStreamName(newName)
						setModalRename(false)
					})
					.catch((resp) => {
						console.error('ERROR_STREAM_RENAMING')
					})
					.finally(() => {
						setProcessing(false)
					})
			}else{// если нет, то просто переименовываем
				setStreamName(newName)
				setProcessing(false)
				setModalRename(false)
			}
		}
	}

	const getBaseQuery = () => {
		let q = []
		if(search.get('concepts'))
			q.push('concepts=' + search.get('concepts'))
		if(search.get('sources'))
			q.push('sources=' + search.get('sources'))
		if(search.get('q'))
			q.push('q=' + search.get('q'))
		if(search.get('dateFrom'))
			q.push('dateFrom=' + search.get('dateFrom'))
		if(search.get('namedEntities'))
			q.push('namedEntities=' + search.get('namedEntities'))

		return q
	}

	const saveStream = () => {
		//userID query name concepts platforms
		if(user.id) {
			setProcessing(true)
			analyticsBACK.post('/stream', {
				userID: user.id,
				query: query,
				concepts: 'из запроса',
				platforms: sourcesArray.join(','),
				docs_count: docsTotal,
				name: ((streamName === 'Название еще не введено')? "Лента " + searchQuery.map(query => {return query.text}).join(' ') : streamName),
				comment: streamComment
			})
				.then(resp => {
					dispatch(setStreamList(resp.data))
					if (!search.get("from_saved")) {
						search.append('from_saved', resp.data[0].id)
						let q = getBaseQuery()
						window.history.replaceState(null, "", `/stream/content?${q.join('&')}&from_saved=${resp.data[0].id}`)
						setTmpStreamID(resp.data[0].id)
					}
					setProcessing(false)
				})
				.catch(resp => {
					console.error("SAVING_STREAM_FAIL")
					setProcessing(false)
				})
		}
	}

	const removeStream = () => {
		if(search.get('from_saved')){
			setProcessing(true)
			analyticsBACK.delete('/stream', {
				params: {
					streamID: search.get('from_saved'),
					userID: user.id
				}
			})
				.then(resp => {
					if(resp.data){
						dispatch(setStreamList(resp.data))
						search.delete("from_saved")
						let q = getBaseQuery()
						window.history.replaceState(null, "New Page Title", `/stream/content?${q.join('&')}`)
					}

					setProcessing(false)
				})
				.catch(resp => {
					console.error("REMOVING_STREAM_FAIL")
					setProcessing(false)
				})
		}
	}

	const makeReport = async () => {
		setFileCreating(true)
		setModalOpened(false)
		let sections = []
		//stream statistics
		const isStreamStat = (document.querySelector('input[name="streamStat"]'))? document.querySelector('input[name="streamStat"]').checked : false
		setDownloadMode('file')

		if(isStreamStat){
			const svg = document.querySelector("#streamStat svg")
			const conceptLinksPNG = await convertSVGtoImg({svg: svg})

			sections.push({type: 'image', content: conceptLinksPNG, title: 'Статистика упоминаний в документах', name: 'docs_stat_' + user.id + '.png'})
		}

		//concept facet
		const isFacet =  (document.querySelector('input[name="streamFacet"]'))? document.querySelector('input[name="streamFacet"]').checked : false
		if(isFacet){
			let metaConceptFacet = document.querySelector('#concept_facet')
			if(metaConceptFacet) {
				metaConceptFacet = metaConceptFacet.innerHTML
				sections.push({type: 'html', content: metaConceptFacet, title: 'Наиболее часто встречающиеся концепты:'})
			}
		}

		//entities facet
		if(isFacet){
			let metaEntityFacet = document.querySelector('#named_facet')
			if(metaEntityFacet) {
				metaEntityFacet = metaEntityFacet.innerHTML
				sections.push({type: 'html', content: metaEntityFacet, title: 'Наиболее часто встречающиеся сущности:'})
			}
		}

		//tokens
		const isTokens = (document.querySelector('input[name="tokens"]'))? document.querySelector('input[name="tokens"]').checked : false
		if(isTokens){
			let tokensHtml = document.querySelector('#tokens')
			if(tokensHtml) {
				tokensHtml = tokensHtml.innerHTML
				sections.push({type: 'html', content: tokensHtml, title: 'Наиболее частые слова:'})
			}
			let stopWords = document.querySelector('.js-stop-words')
			if(stopWords && stopWords.innerHTML){
				stopWords = "<br /><p>Стоп слова:</p><br />" + stopWords.innerHTML
				sections.push({type: 'html', content: stopWords})
			}
		}

		//tokens graph
		const isTokensGraph = (document.querySelector('input[name="textStatGraph"]'))? document.querySelector('input[name="textStatGraph"]').checked : false
		if(isTokensGraph){
			const svgTokens = document.querySelector("#tokensGraph svg")
			const svgTokensPNG = await convertSVGtoImg({svg: svgTokens})
			const tgTitle = document.querySelector('#tokensGraph .title').innerText

			sections.push({type: 'image', content: svgTokensPNG, title: tgTitle, name: 'docs_token_stat_' + user.id + '.png'})
		}

		//context
		const isContext = (document.querySelector('input[name="contexts"]'))? document.querySelector('input[name="contexts"]').checked : false
		if(isContext){
			let contextHtml = document.querySelector('#contexts')
			if(contextHtml){
				contextHtml = contextHtml.innerHTML
				let contextHTMLTitle = document.querySelector('.context_wrapper .title').innerText
				sections.push({type: 'table', content: contextHtml, title: contextHTMLTitle})
			}
		}

		//stream meta
		const isStreamMeta = (document.querySelector('input[name="streamMeta"]'))? document.querySelector('input[name="streamMeta"]').checked : false
		if(isStreamMeta){
			let metaHtml = document.querySelector('#streamMeta').innerHTML
			sections.push({type: 'html', content: metaHtml})
		}

		//comment
		const isStreamComment = (document.querySelector('input[name="streamComment"]'))? document.querySelector('input[name="streamComment"]').checked : false
		if(isStreamComment){
			if(streamComment){
				sections.push({type: 'text', content: streamComment, title: 'Комментарий'})
			}
		}

		//docs
		const isConceptDocs = (document.querySelector('input[name="ConceptDocs"]'))? document.querySelector('input[name="ConceptDocs"]').checked : false
		if(isConceptDocs) {
			let docsContent = document.createElement('DIV')
			let docsContentParent = document.querySelector('#ConceptDocs')
			let docsHTML = ''
			docsContent.innerHTML = docsContentParent.innerHTML
			docsContent.querySelectorAll('.tooltip, .tabs-head, .detail-material-itm-content__top a').forEach(obj => {
				obj.remove()
			})
			docsContent.querySelectorAll('.detail-material-itm-content__info__date').forEach(obj => {//parse date
				if(obj.innerText.length > 0)
					obj.innerHTML = obj.querySelector('span:first-child').innerText + ' ' + obj.querySelector('span:last-child').innerText
			})

			if (docsContentParent.querySelector('.tabs-head__itm.active').innerText === 'Только заголовок') {
				docsContent.querySelectorAll('.detail-material-itm-content__text').forEach(obj => {
					obj.remove()
				})
			} else if (docsContentParent.querySelector('.tabs-head__itm.active').innerText === 'Заголовок + анонс') {
				docsContent.querySelectorAll('.detail-material-itm-content__text').forEach(obj => {
					obj.querySelector('.detail-material-itm-content__text__inner').innerText = (obj.innerText.length > 300) ? obj.innerText.substring(0, 300) + '...' : obj.innerText;
				})
			}

			docsContent.querySelectorAll('.detail-material-itm').forEach(obj => {
				if (obj.querySelector('input[type="checkbox"]').checked) {
					docsHTML += obj.querySelector('.detail-material-itm-content').outerHTML
				}
			})
			sections.push({type: 'html', content: docsHTML, title: 'Материалы'})
		}

		//sending report data
		if(sections.length > 0) {
			// let concepts = conceptsArray.join(',') + '-' + sourcesArray.join(',')

			analyticsBACK.post('/reports',
				{
					sections: sections,
					concept: '',
					conceptName: ((streamName === 'Название еще не введено')? "Лента " + searchQuery.map(query => {return query.text}).join(' ') : streamName) + ' .docx',
					report_type: 'stream',
					user_id: user.id,
					link: '/stream/content' + query
				},
				// {responseType: 'blob'}
			)
				.then(resp => {
					if (resp.data.file) {
						setModalOpened(true)
						setReportDownloadLink(resp.data.file)
						// fileDownload(resp.data, `report${new Date().toLocaleDateString('ru')}.docx`);
					} else {
						console.error('bad request')
					}
					setFileCreating(false)
					setModalOpened(true)
				})
				.catch((resp) => {
					setFileCreating('fail')
				})
		}
		return true
	}

	const convertHTMLEntity = (text) => {
		let span = document.createElement('span');
		return text
			.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
				span.innerHTML = entity;
				let res = span.innerText;
				span.remove()
				return res.replace('"', '\"');
			});
	}

	const exportFile = ({csvContent, format = '.csv'}) => {
		// let concepts = conceptsArray.join(',') + '-' + sourcesArray.join(',')
		setFileCreating(true)
		setModalOpened(false)
		analyticsBACK.post('/reports/csv',
			{
				concept: '',
				conceptName: ((streamName === 'Название еще не введено')? ((save)? "Лента " : 'Поиск ') + searchQuery.map(query => {return query.text}).join(' ') : streamName) + format,
				report_type: 'stream_voyant',
				userID: user.id,
				link: '/stream/content' + query,
				content: csvContent
			},
		)
			.then(resp => {
				if (resp.data.file) {
					setModalOpened(true)
					setReportDownloadLink(resp.data.file)
				} else {
					console.error('bad request')
				}
				setFileCreating(false)
				setModalOpened(true)
			})
			.catch(() => {
				setFileCreating('fail')
			})
	}

	const makeReportCSV = () => {
		const inputs = document.querySelectorAll('#ConceptDocs label.checkbox input[type="checkbox"]:checked')
		let csvContent =
`дата публикации;источник;заголовок;автор;текст документа;URL документа в оригинале
`
		setDownloadMode('file')
		if(inputs){
			inputs.forEach((check) => {
				let selectedDoc = docsLoadedList[check.dataset.id]
				if(selectedDoc){
					let pubDate = new Date((selectedDoc.publicationDate)? parseInt(selectedDoc.publicationDate) * 1000 : parseInt(selectedDoc.systemRegistrationDate) * 1000)
					let docPlatformName = (selectedDoc.metadata.platform)? selectedDoc.metadata.platform.name.replace('"', '\"') : '';
					csvContent +=
`${pubDate.toLocaleDateString('ru')};"${docPlatformName}";"${convertHTMLEntity(selectedDoc.title)}";"${(selectedDoc.metadata.account)?? ''}";"${(parseText({text: selectedDoc.text}).__html.replace(/\n/g, '').replace(/;/g, ''))}";${selectedDoc.externalUrl}
`
				}
			})

			exportFile({csvContent})
		}
	}

	const makeReportForVoyant = () => {
		const htmspecialcharts = (str) => {
			str = str.replace(/&/mg, '&amp;')
			return str
				.replace(/</mg, '&lt;')
				.replace(/>/mg, '&gt;')
				.replace(/"/mg, '&quot;')
				.replace(/'/mg, '&#039;')
				.replace(/■/mg, '')
				.replace(/□/mg,'')
		}

		const inputs = document.querySelectorAll('#ConceptDocs label.checkbox input[type="checkbox"]:checked')

		setDownloadMode('voyant')
		let csvContent = `
<div class="Collection" role="Collection">`
		if(inputs){
			inputs.forEach((check) => {
				let selectedDoc = docsLoadedList[check.dataset.id]
				let pubDate = new Date((selectedDoc.publicationDate)? parseInt(selectedDoc.publicationDate) * 1000 : parseInt(selectedDoc.systemRegistrationDate) * 1000)
				if(selectedDoc){
					csvContent +=`
<div class="Article" role="Article">
	<div role="Publication Date">${(pubDate)? pubDate.toISOString() : ''}</div>
	<div class="Title">${convertHTMLEntity(selectedDoc.title)}</div>
	<div role="Publisher" class="Publisher">${(selectedDoc.metadata.account)?? ''}</div>
	<div class="Content" role="Content">	
		${htmspecialcharts( parseText({text: selectedDoc.text, skipHeaders: true, textMode: true}) )}
	</div>
</div>

`
				}
			})
			csvContent += `
</div>`
			exportFile({csvContent, format: 'voyant tools'})
		}
	}


	return (
		<main className="">
			<div className={'search-phrases-wrapper'}>
				<div className="content visible">
					<div className="title-big c-main-dark ">Результаты поиска по поисковому запросу:</div>
				</div>
			</div>
			<div className={'search-phrases-sticky'}>
				<div className={'content visible '}>
					<div className="phrases">
						<div className="stream-param">
							{searchQuery.length > 0 &&
								<div className='stream-query'>
									{searchQuery.map((phrase, index) => {
										if (phrase.type === 'bool'){
											return <div key={`${phrase.text}_${index}`} className={`search-phrases__item search-phrases__item--bool ${phrase.text}`}>{phrase.text}</div>
										}
										if(phrase.type === 'text')
											return <div key={`${phrase.text}_${index}`} className='search-phrases__item title search-phrases__item--text'>{phrase.text}</div>

										if(phrase.type === 'concept')
											return <div key={`${phrase.text}_${index}`} className='search-phrases__item title search-phrases__item--concept'>{phrase.text}</div>

										return <></>
									})}
								</div>
							}
						</div>

						<div className="">
							{save
								? processing
									? <Skeleton width="200px" height="78px" />
									: search.get('from_saved')
										?<div className="btn stream-finish-btn" onClick={() => {removeStream()}}>Удалить ленту</div>
										:<div className="btn stream-finish-btn" onClick={() => {saveStream()}}>Сохранить ленту</div>
								: <></>
							}
						</div>
					</div>
				</div>
			</div>
			<div className="content visible">
				<div className="query-detail-page">

					{sourcesArray.length > 0 &&
						<div className="row">
							<PlatformsOnStream platformsID={sourcesArray} />
						</div>
					}

					<div className="query-detail-section center">
						<div className="query-detail-section__content">
						</div>
						<div className="query-detail-section__aside">
							<div className="small light ta-c">
								Включать в&nbsp;отчет
							</div>
						</div>
					</div>
					{!save &&
						<QueryDetailLine name="streamStat" checkboxes={checkboxes} setCheckboxes={setCheckboxes}>
							{qString &&
								<StreamDetailSource
									sourcesArray={sourcesArray}
									qString={qString}
									defVars={queryConcept}
								/>
							}
						</QueryDetailLine>
					}
					{save &&
						<div className="query-detail-section center">
							<div className="query-detail-section__content">
							<span className="stream-rename-btn c-main" onClick={(e) => {
								setModalRename(true)
							}}>
								Переименовать ленту
							</span>
								<Modal className="stream-rename-modal" opened={modalRename}
								       setOpened={setModalRename}>
									<form action="" onSubmit={e => renameStream(e)} className="nice-form">
										<div className="title ta-c c-main-dark stream-rename-modal__title">Введите
											название сохраняемой ленты
										</div>
										<textarea name="stream-name" rows="4" placeholder="Название ленты"></textarea>
										<button className="btn small stream-rename-modal__btn">Сохранить</button>
									</form>
								</Modal>
							</div>
						</div>
					}

					<QueryDetailLine name="streamMeta" checkboxes={checkboxes} setCheckboxes={setCheckboxes}>
						<div id='streamMeta'>
							{save
								?<h1 className="title-big c-main-dark">
									{streamName}
								</h1>
								:<h1 className="title-big c-main-dark">
									Количество найденных материалов
								</h1>
							}

							<div className="stream-num-block">
								<div className="title-big c-main-dark">
									{docsTotal !== false
										? docsTotal === 'fail'
											? <></>
											: docsTotal
										: <Skeleton width="60px" />
									}
								</div>
								<div className="stream-num-block__text">
									Количество найденных материалов,<br/>
									удовлетворяющих условиям поиска
								</div>
							</div>
						</div>
					</QueryDetailLine>

					{qString &&
						<>
							<QueryDetailLine name="streamFacet" checkboxes={checkboxes} setCheckboxes={setCheckboxes}>
								<StreamFacet filterSettings={(sourcesArray[0] === 'all')
									? {searchString: qString, childVisibility: "all", namedEntities: namedEntities, concepts: conceptsInQuery}
									: {searchString: qString, childVisibility: "all", platforms: sourcesArray, namedEntities: namedEntities, concepts: conceptsInQuery}
								} />
							</QueryDetailLine>

							<TextStat
								checkboxes={checkboxes} setCheckboxes={setCheckboxes}
								vars={
									{
										limit: 100, FS: {
											tdmProcessStage: "all",
											searchString: qString,
											platforms: (sourcesArray[0] === 'all')? null : sourcesArray,
											childVisibility: "all",

											namedEntities: namedEntities,
											concepts: conceptsInQuery
									},  ...queryConcept}
								}
								pages={10}
							/>

							<ConceptDocs
								defVars = {
									{
										limit: 20, FS: {
											tdmProcessStage: 'all',
											searchString: qString,
											platforms: (sourcesArray[0] === 'all')? null : sourcesArray,
											childVisibility: "all",
											namedEntities: namedEntities,
											concepts: conceptsInQuery
									},  ...queryConcept}
								}
								query = {STORIES_BY_QUERY_AND_PLATFORMS}
								datesQuery = {STORIES_BY_QUERY_AND_PLATFORMS}
								title = ''
								checkboxes={checkboxes}
								setCheckboxes={setCheckboxes}
								materialCheckboxes={materialCheckboxes}
								setMaterialCheckboxes={setMaterialCheckboxes}
								resp = {setDocsPage}
								setDates ={false}
								storedCount={storedCount}
								setDocsCountParent = {setDocsTotal}
								// fullList = {docsLoadedList}
								// setFullList = {setDocuments}
							/>
						</>
					}
					<hr/>
					<QueryDetailLine name="streamComment" checkboxes={checkboxes} setCheckboxes={setCheckboxes}>
						<div className="nice-form">
							<div className="title c-main-dark mb-30">Комментарий к ленте</div>
							<textarea onChange={e => setStreamComment(e.target.value)} value={streamComment} name="" id="" rows="6" placeholder="Введите комментарий"></textarea>
						</div>
					</QueryDetailLine>

					{Object.keys(docsLoadedList).length > 0 &&
						<div className="detail-export stream_export">
							<div className="flex_row flex_row--stretch flex_row--gap_w_double">
								<div aria-disabled={(fileCreating)} className="btn detail-export__btn" onClick={() =>{
									// if(!reportDownloadLink)
									makeReport()
									// else
								}}>
									<HandySvg src={ico_doc} width={28} height={34}></HandySvg>
									Экспорт отчета в формате .docx
								</div>

								<div aria-disabled={(fileCreating)} className="btn detail-export__btn" onClick={() =>{
									// if(!reportDownloadLink)
									makeReportCSV()
									// else
								}}>
									<HandySvg src={ico_doc} width={28} height={34}></HandySvg>
									Экспорт отчета в формате .csv
								</div>

								<div aria-disabled={(fileCreating)} className="btn detail-export__btn" onClick={() =>{
									makeReportForVoyant()
								}}>
									<svg width="31" height="31" viewBox="0 0 24 24">
										<path d="M12,16C12.56,16.84 13.31,17.53 14.2,18L12,20.2L9.8,18C10.69,17.53 11.45,16.84 12,16M17,11.2A2,2 0 0,0 15,13.2A2,2 0 0,0 17,15.2A2,2 0 0,0 19,13.2C19,12.09 18.1,11.2 17,11.2M7,11.2A2,2 0 0,0 5,13.2A2,2 0 0,0 7,15.2A2,2 0 0,0 9,13.2C9,12.09 8.1,11.2 7,11.2M17,8.7A4,4 0 0,1 21,12.7A4,4 0 0,1 17,16.7A4,4 0 0,1 13,12.7A4,4 0 0,1 17,8.7M7,8.7A4,4 0 0,1 11,12.7A4,4 0 0,1 7,16.7A4,4 0 0,1 3,12.7A4,4 0 0,1 7,8.7M2.24,1C4,4.7 2.73,7.46 1.55,10.2C1.19,11 1,11.83 1,12.7A6,6 0 0,0 7,18.7C7.21,18.69 7.42,18.68 7.63,18.65L10.59,21.61L12,23L13.41,21.61L16.37,18.65C16.58,18.68 16.79,18.69 17,18.7A6,6 0 0,0 23,12.7C23,11.83 22.81,11 22.45,10.2C21.27,7.46 20,4.7 21.76,1C19.12,3.06 15.36,4.69 12,4.7C8.64,4.69 4.88,3.06 2.24,1Z"></path>
									</svg>
									Экспорт документов в<br />Voyant Tools
								</div>
							</div>
							{fileCreating === 'fail' &&
								<p className='ta-c' style={{fontSize: "1.5rem"}}>Ошибка при создании отчета</p>
							}
							<Modal className="modal--export" opened={modalOpened} setOpened={setModalOpened}>
								<div className="modal-export-top">
									{reportDownloadLink && downloadMode === 'file' &&
										<a target={"_blank"}  rel="noreferrer" className="btn" href={`${BACK_HOST}/public/${reportDownloadLink}`}  download={true}>
											<HandySvg src={ico_doc} width={28} height={34}></HandySvg> Скачать файл отчета
										</a>
									}
									{reportDownloadLink && downloadMode === 'voyant' &&
										<Link to={`/stream/voyant?file=${BACK_HOST}/public/${reportDownloadLink}`}  className="btn">
											<svg width="31" height="31" viewBox="0 0 24 24">
												<path d="M12,16C12.56,16.84 13.31,17.53 14.2,18L12,20.2L9.8,18C10.69,17.53 11.45,16.84 12,16M17,11.2A2,2 0 0,0 15,13.2A2,2 0 0,0 17,15.2A2,2 0 0,0 19,13.2C19,12.09 18.1,11.2 17,11.2M7,11.2A2,2 0 0,0 5,13.2A2,2 0 0,0 7,15.2A2,2 0 0,0 9,13.2C9,12.09 8.1,11.2 7,11.2M17,8.7A4,4 0 0,1 21,12.7A4,4 0 0,1 17,16.7A4,4 0 0,1 13,12.7A4,4 0 0,1 17,8.7M7,8.7A4,4 0 0,1 11,12.7A4,4 0 0,1 7,16.7A4,4 0 0,1 3,12.7A4,4 0 0,1 7,8.7M2.24,1C4,4.7 2.73,7.46 1.55,10.2C1.19,11 1,11.83 1,12.7A6,6 0 0,0 7,18.7C7.21,18.69 7.42,18.68 7.63,18.65L10.59,21.61L12,23L13.41,21.61L16.37,18.65C16.58,18.68 16.79,18.69 17,18.7A6,6 0 0,0 23,12.7C23,11.83 22.81,11 22.45,10.2C21.27,7.46 20,4.7 21.76,1C19.12,3.06 15.36,4.69 12,4.7C8.64,4.69 4.88,3.06 2.24,1Z"></path>
											</svg>
											Открыть в Voyant
										</Link>
									}
								</div>
							</Modal>
						</div>
					}

				</div>
			</div>
		</main>
	)
}

export default PageStreamDetail;