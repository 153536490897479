import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {analyticsBACK} from "../../ApiEndpoints";
import {setStreamList} from "../../reducers/streamsSlice";
import {Link} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const History = () => {
	const [history, setHistory] = useState(false)
	const user = useSelector(state => state.user.value)

	useEffect(() => {
		document.title = 'МГИМО аналитика: журнал действий'

		analyticsBACK.get('/history/all', {
			params:{
				userID: user.id
			}
		})
			.then(resp => {
				if(resp.data)
					setHistory(resp.data)
			})
			.catch(resp => {
				console.error('Ошибка получения истории')
			})
	}, [])

	const tableHeader = () => {
		const header = [
			"Название",
			"Дата"
		]
		return <tr key='tableHead'>
			{header.map(itm => <th>{itm}</th>)}
		</tr>
	}

	const tableContent = () => {
		if(Array.isArray(history)){
			return history.map(stream => {
				let create = new Date(stream.created_at)
				return <tr>
					<td>
						<Link key={stream.id} to={`${stream.link}`} className="title c-main-dark">{stream.title}</Link>
					</td>
					<td>
						{create.toLocaleDateString('ru')}<br />
						<span className='c-gray-bg'>{create.toLocaleTimeString('ru')}</span>
					</td>
				</tr>
			})
		}
	}

	const makeSkeleton = () => {
		return <tr>
			<td>
				<p className='title c-main-dark'><Skeleton width="250px" highlightColor='#412779' /></p>
			</td>
			<td>
				<Skeleton width="150px" />
				<span className='c-gray-bg'><Skeleton width="100px"/></span>
			</td>
		</tr>
	}

	return (
		<main className={'main-content'}>
			<div className="content visible">
				<h1 className="title-big main-margin c-main-dark">Журнал действий</h1>

				<table className="stream-table">
					<thead>
					{tableHeader()}
					</thead>
					<tbody>
					{history === false
						? makeSkeleton()
						: tableContent()
					}
					</tbody>
				</table>

			</div>
		</main>
	);
}

export default History