import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import LoaderPage from "../../components/laders/LoaderPage";
import authLogo from "../../img/auth-logo.svg";
import authText from "../../img/auth-text.svg";
import AuthForm from "../../components/auth/AuthForm";
import FormErrors from "../../components/ui/FormErrors";
import {HandySvg} from "handy-svg";
import svg_arrow from "../../ico/arrow.svg";
import {analyticsBACK} from "../../ApiEndpoints";

const InputField = ({children}) => {
	return <div className="col">
		<label className="input-wrap">
			{children}
		</label>
	</div>
}

const Invite = () => {
	const {secret} = useParams()
	const navigate = useNavigate();
	const [formBusy, setFormBusy] = useState(false)
	const [errors, setErrors] = useState([])
	const [regSuccess, setRegSuccess] = useState('init')
	//состав формы
	const fields = [
		{name: 'name', required: true, label: 'Имя'},
		{name: 'lastname', required: true, label: 'Фамилия'},
		{name: 'job', required: true, label: 'Место работы/учебы'},
		{name: 'post', required: true, label: 'Должность'},
		{name: 'email', type: 'email', required: true, label: 'Email'},
		{type: 'empty'},
		{name: 'password', type: 'password', required: true, label: 'Пароль'},
		{name: 'passwordRepeat', type: 'password', required: true, label: 'Повтор пароля'},
	]

	const makeForm = () => {
		return <div className="cols cols-2 p10" >
			{fields.map((input) => {
				if(input.type === 'empty')
					return <div className="col"></div>

				return <InputField>
					<input
						name={input.name}
						type={(input.type)?? 'text'}
						placeholder=" "
						required={(input.required)?? 'false'}
					/>
					<span className="input-wrap__label">{input.label}</span>
				</InputField>
			})}
		</div>
	}

	const handleRegister = (e) => {
		e.preventDefault()
		if(e.target.password.value.length < 6 ){
			setErrors(['Минимальная длинна пароля - 6 символов.'])
			return ''
		}
		setErrors([])
		if(e.target.password.value && e.target.passwordRepeat.value && e.target.password.value === e.target.passwordRepeat.value){
			setFormBusy(true)
			const formData = new FormData(e.target)
			analyticsBACK.post('/register', formData)
				.then(resp => {
					if(resp.data && resp.data.data && resp.data.data.updateUserPassword && resp.data.data.updateUserPassword === true)
						navigate('/invite/success', {replace: true})
					else
						navigate('/confirm', {replace: true})
				})
				.catch(resp => {
					if(resp.response.data.message)
						setErrors([resp.response.data.message])
					if(resp.data.message)
						setErrors([resp.data.message])
					console.error('Create user fail')
				})
				.finally(() => {
					setFormBusy(false)
				})
		}else {
			setErrors(['Пароли не совпадают'])
		}
	}


	return <div className="auth-page">
		<div className="content visible">
			<LoaderPage loading="" />
			<div className="auth-page-content">
				<div className="auth-page-content__left">
					<img src={authLogo} alt="" className="auth-page-logo"/>
				</div>
				<div className="auth-page-content__right">
					<img src={authText} alt="" className="auth-page-text"/>

					<div className="title">Регистрация</div>

					<p className="small light auth-page-desc">Для получения доступа к базе данных и формированию
						аналитических работ заполните все поля регистрационной формы.</p>

					<form action="" aria-disabled={formBusy} className="nice-form js-auth-form" onSubmit={(e) => handleRegister(e)}>
						<input type="hidden" name="invite" value={(secret)?? ''} />

						{makeForm()}

						<div className="form-message"><FormErrors errors={errors} /></div>

						<button type='submit' className="btn big">
							Регистрация
							<HandySvg src={svg_arrow} width={20} height={18}/>
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
}

export default Invite