import React, {useEffect, useRef, useState} from "react"
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {analyticsBACK, APIClient} from "../../ApiEndpoints";
import {
	CONCEPT_DOCS_PAGE,
	CONCEPT_LINKS,
	CONCEPT_META,
	CONCEPT_DOCS_PAGE_DATES_FILTER,
	CONCEPT_DOCS_STAT_PAGE
} from "../../graphql/conceptQueries";
import {isArray} from "@apollo/client/cache/inmemory/helpers";
import Modal from "../../components/ui/Modal";
import {HandySvg} from "handy-svg";

import ico_doc from "../../ico/doc.svg"
import "../../styles/pages/dossier.sass"
import {useDispatch, useSelector} from "react-redux";
import {BACK_HOST} from "../../Consts";
import {setHistory} from "../../reducers/userSlice";
import RememberList from "../../components/search/PememberList";
import Skeleton from "react-loading-skeleton";

const ConceptEvents = React.lazy(() => import("../../components/concepts/connections/ConceptEvents"))
const ConceptMeta = React.lazy(() => import("../../components/concepts/dossier/ConceptMeta"))
const ConceptInSearch = React.lazy(() => import("../../components/concepts/dossier/ConceptInSearch"))
const RelatedConcepts = React.lazy(() => import("../../components/concepts/dossier/RelatedConcepts"))
const ConceptInDocs = React.lazy(() => import("../../components/concepts/dossier/ConceptInDocs"))
const ConceptDocs = React.lazy(() => import("../../components/concepts/dossier/ConceptDocs"))
const ConceptDocStat = React.lazy(() => import("../../components/concepts/dossier/ConceptDocStat"))
const ConceptSources = React.lazy(() => import("../../components/concepts/dossier/ConceptSources"))
const ConnectionsGraph = React.lazy(() => import("../../components/concepts/connections/ConnectionsGraph"))

const Dossier = () => {
	const user = useSelector(state => state.user.value)
	const [checkboxes, setCheckboxes] = useState({
		conceptMeta: true,
		relatedConcepts: true,
		objectsInDocs: true,
		ConceptDocs: true,
		DocStat: true,
		ConceptSources: true,
		conceptInQuery: true
	})
	const [conceptMeta, setConceptMeta] = useState(false)
	const [conceptLinks, setConceptLinks] = useState(false)
	const [conceptDocsLink, setConceptDocsLink] = useState(false)
	const [kbDocsCount, setKbDocsCount] = useState(false)
	const [kbDocsTotal, setKbDocsTotal] = useState(false)
	const [materialCheckboxes, setMaterialCheckboxes] = useState({});
	const [sources, setSources] = useState(false)
	const {conceptId} = useParams()
	const [modalOpened, setModalOpened] = useState(false);
	const main = useRef()
	const [fileCreating, setFileCreating] = useState(false)
	const [reportDownloadLink, setReportDownloadLink] = useState(false)
	const pathName = useLocation()
	const dispatch = useDispatch()
	const [types, setTypes] = useState(false)
	const [selectedTypes, setSelectedTypes] = useState(false)
	const [links, setLinks] = useState(false)
	const [filteredLinks, setFilteredLinks] = useState([])
	const navigate = useNavigate();

	if(user.group.code === 'outer')
		navigate('/')

	useEffect(() => {//устанавливаем заголовок и пугим в журонал действие
		document.title = 'МГИМО аналитика: досье ' + ((conceptMeta && conceptMeta.name)? conceptMeta.name : '')
		if(conceptMeta && conceptMeta.name){
			analyticsBACK.post('/history', {
				userID: user.id,
				link: pathName.pathname,
				title: 'Досье концепта ' + conceptMeta.name
			})
				.then(resp => {
					if(resp.data)
						dispatch(setHistory(resp.data))
				})
				.catch(resp => {
					console.error('Ошибка добавления в историю')
				})
		}
	}, [conceptMeta])

	useEffect(() => {
		//getting concept props
		if(!conceptMeta) {
			APIClient.query({
				query: CONCEPT_META,
				variables: {
					id: conceptId
				}
			})
				.then(resp => {
					if (resp.data.concept.paginationConceptProperty) {
						setConceptMeta({
							id: resp.data.concept.id,
							name: resp.data.concept.name,
							props: resp.data.concept.paginationConceptProperty.listConceptProperty,
							metric: resp.data.concept.metric,
							image: resp.data.concept.image
						})
					} else {
						setConceptMeta('none')
					}
				})
				.catch(() => {
					setConceptMeta('error')
				})
		}

		//getting concept links
		if(!conceptLinks) {
			APIClient.query({
				query: CONCEPT_LINKS,
				variables: {
					id: conceptId
				}
			})
				.then(resp => {
					const tmpLinks = {}
					const conceptLinkTypes = {}
					if (resp.data.concept && resp.data.concept.paginationConceptLink && resp.data.concept.paginationConceptLink.listConceptLink){
						setConceptLinks(resp.data.concept.paginationConceptLink.listConceptLink)
						if(resp.data.concept.paginationConceptLink.total > 0) {
							resp.data.concept.paginationConceptLink.listConceptLink.forEach(link => {
								const linkType = link.conceptLinkType
								conceptLinkTypes[linkType.id] = linkType
							})
							tmpLinks[conceptId] = resp.data.concept.paginationConceptLink.listConceptLink
						}
						if(Object.keys(conceptLinkTypes).length > 0)
							setTypes(conceptLinkTypes)
						else
							setTypes('none')

						if(Object.keys(tmpLinks).length > 0){
							setLinks(tmpLinks)
							setFilteredLinks(tmpLinks)
						}
						else
							setLinks("fail")
					}

				})
				.catch(resp => {
					setConceptLinks('fail')
				})
		}
	},[])

	useEffect(() => {//periodically fetching docs
		const count = (kbDocsCount)? kbDocsCount : 0
		let conceptDocsLinks = (conceptDocsLink)? conceptDocsLink : {}
		let _sources = (sources)? sources : {}

		APIClient.query({
			query: CONCEPT_DOCS_STAT_PAGE,
			variables: {
				id: conceptId,
				limit: 20,
				offset: count
			}
		})
			.then(resp => {
				const docs = resp.data.paginationKBRelatedDocument.listDocument
				if(!docs){
					setConceptDocsLink('fail')
					setSources('fail')
					return false
				}

				docs.forEach((doc) => {
					const concepts = doc.paginationConceptFact.listConceptFact
					if(isArray(concepts)) {
						let docConcepts = []
						concepts.forEach((concept) => {
							if ( concept.concept.id !== conceptId
								&& !docConcepts.includes(concept.concept.id)
								&& !concept.concept.conceptType.isEvent
							) {//если это не родительский и не событие
								docConcepts.push('conceptId')
								conceptDocsLinks = {
									...conceptDocsLinks,
									[concept.concept.id]: {
										y: (conceptDocsLinks[concept.concept.id]) ? conceptDocsLinks[concept.concept.id].y + 1 : 1, //count
										x: (concept.concept.name.split(' ').length > 3) ? concept.concept.name.split(' ')[0] + ' ' + concept.concept.name.split(' ')[1] + ' ' + concept.concept.name.split(' ')[2] : concept.concept.name //name
									}
								}
							}
						})
						docConcepts = []
					}
					if(doc.metadata){
						if(doc.metadata.platform){
							_sources[doc.metadata.platform.id] = {type: 'platform', ...doc.metadata.platform, count: ((_sources[doc.metadata.platform.id] && _sources[doc.metadata.platform.id].count)? _sources[doc.metadata.platform.id].count + 1 : 1)}
						}
					}
				})
				setSources({...sources, ..._sources})
				setConceptDocsLink(conceptDocsLinks)
				if(resp.data.paginationKBRelatedDocument && resp.data.paginationKBRelatedDocument.total)
					setKbDocsTotal(resp.data.paginationKBRelatedDocument.total)

				if(kbDocsCount)
					setKbDocsCount(kbDocsCount + docs.length)
				else
					setKbDocsCount(docs.length)
			})
			.catch(resp => {
				setConceptDocsLink('fail')
				setSources('fail')
			})
	},[kbDocsCount])

	const loadImage = async url => {//загружаем картинку
		const img = document.createElement('img')
		img.src = url
		return new Promise((resolve, reject) => {
			img.onload = () => resolve(img)
			img.onerror = reject
		})
	}
	const convertSVGtoImg = async ({svg, format = 'png'}) => {//конвертируем svg в картинку
		const svgAsXML = (new XMLSerializer()).serializeToString(svg)
		const svgData = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`

		const img = await loadImage(svgData)

		const canvas = document.createElement('canvas')
		canvas.width = svg.clientWidth
		canvas.height = svg.clientHeight
		canvas.getContext('2d').drawImage(img, 0, 0, svg.clientWidth, svg.clientHeight)

		const dataURL = await canvas.toDataURL(`image/${format}`, 1.0)
		return dataURL;
	}



	useEffect(() => {//фильтруем связи
		if(selectedTypes && Object.keys(selectedTypes).length > 0){
			const tmp = {}
			for (const [key, value] of Object.entries(links)) {
				tmp[key] = []
				value.forEach(link => {
					if( Object.keys(selectedTypes).includes(link.conceptLinkType.id)){
						tmp[key].push(link)
					}
				})
			}
			setFilteredLinks(tmp)
		}else
			setFilteredLinks(links)
	}, [selectedTypes])

	const handleSetTypes = (type) => {//обрабатываем обновление связей
		if(selectedTypes[type.id]) {
			const tmp = selectedTypes
			delete tmp[type.id]
			setSelectedTypes({...tmp})
		}else{
			const tmp = {}
			tmp[type.id] = type
			setSelectedTypes({...selectedTypes, ...tmp})
		}
	}
	const updateTypes = (newTypes) => {//обновляем типы связей
		if(newTypes)
			setTypes({...types, ...newTypes})
	}

	const updateLinks = (updatedLinks) => {//обновляем связи
		setFilteredLinks(updatedLinks)
		setLinks({...links, ...updatedLinks})
	}

	const makeConnectionsTypes = () => {//вывод кнопок фильтрации по типам связей
		if(types && Object.keys(types).length > 0)
			return Object.values(types).map(type => {
				return <li key={type.id} onClick={() => handleSetTypes(type)} className={(selectedTypes[type.id])? 'active' : ''}>
					<div className="remember-list__itm" >{type.name}</div>
				</li>
			})
	}


	const makeReport = async () => {
		setFileCreating(true)
		setModalOpened(false)
		let sections = []
		//getting meta
		const isConceptMeta = (document.querySelector('input[name="conceptMeta"]'))? document.querySelector('input[name="conceptMeta"]').checked : false
		if(isConceptMeta) {
			let conceptMetaData = document.querySelector('#conceptMeta').outerHTML
			let metaTmpTag = document.createElement("DIV")
			metaTmpTag.innerHTML = conceptMetaData
			let imgContent = false

			if(metaTmpTag.querySelector('.avatar img'))
				imgContent = metaTmpTag.querySelector('.avatar img').getAttribute('src')

			if(metaTmpTag.querySelector('.add-dossier-concept'))
				metaTmpTag.querySelector('.add-dossier-concept').remove()


			metaTmpTag.querySelector('.col-4').remove()
			let metaHTML = metaTmpTag.innerHTML + '<br/><br/>'

			if(imgContent)
				sections.push({
					type: "image",
					content: imgContent,
					title: '',
					name: 'avatar' + user.id + '.png'
				})

			sections.push({type: 'html', content: metaHTML})
			metaTmpTag.remove()
		}
		//getting connections graph
		const isConnectionsGraph = (document.querySelector('input[name="ConnectionsGraph"]'))? document.querySelector('input[name="ConnectionsGraph"]').checked : false
		if(isConnectionsGraph){//SankeyGraph
			const svg = document.querySelector("#SankeyGraph svg")
			const conceptLinksPNG = await convertSVGtoImg({svg: svg})
			sections.push({
				type: "image",
				content: conceptLinksPNG,
				title: 'Граф связей концепта',
				name: 'connections_graph' + user.id + '.png'
			})
		}

		//связи концепта
		const isConceptLinks = (document.querySelector('input[name="relatedConcepts"]'))? document.querySelector('input[name="relatedConcepts"]').checked : false
		if(isConceptLinks){
			let relatedConcepts = document.createElement("DIV")
			relatedConcepts.innerHTML = document.querySelector('#RelatedConcepts').innerHTML
			relatedConcepts.querySelectorAll('.item-with-menu__btn, .tooltip').forEach(obj => {
				obj.remove()
			})
			relatedConcepts.querySelectorAll("td").forEach(obj => {
				obj.innerHTML = obj.innerText
			})

			sections.push({type: 'html', content: relatedConcepts.innerHTML})
		}
		//связанные события
		const isConceptEvents = (document.querySelector('input[name="conceptEvents"]'))? document.querySelector('input[name="conceptEvents"]').checked : false
		if(isConceptEvents){
			document.querySelectorAll('[data-color]').forEach((itm) => {
				itm.setAttribute('style','color:'+itm.dataset.color)
			})
			let relatedEvents = document.createElement("div")
			relatedEvents.innerHTML = document.querySelector('#conceptEvents').innerHTML

			relatedEvents.querySelectorAll('svg').forEach((el) => {
				const parent = el.parentElement
				const p = document.createElement('p')
				p.innerHTML = '&#9906;'
				parent.style.color = parent.dataset.color + 'ff'
				parent.append(p)
			})
			relatedEvents.querySelectorAll('.item-with-menu__btn, .tooltip').forEach(obj => {
				obj.remove()
			})

			relatedEvents.querySelectorAll("td").forEach(obj => {
				obj.innerHTML = obj.innerText
			})
			sections.push({type: 'html', content: relatedEvents.innerHTML})
			relatedEvents.remove()
		}
		//связанные события на карте
		const isConceptsEventsMap = (document.querySelector('input[name="conceptEventsMap"]'))? document.querySelector('input[name="conceptEventsMap"]').checked : false
		if(isConceptsEventsMap) {
			if(document.querySelector("#conceptEventsMap svg")){
				const svg = document.querySelector("#conceptEventsMap svg")
				const conceptLinksPNG = await convertSVGtoImg({svg: svg})
				sections.push({type: "image", content: conceptLinksPNG, title: 'События на карте', name: 'concept_events_graph' + user.id + '.png'})
			}
		}

		//концепты в тех же документах
		const isConceptsInDocs = (document.querySelector('input[name="objectsInDocs"]'))? document.querySelector('input[name="objectsInDocs"]').checked : false
		if(isConceptsInDocs) {
			if(document.querySelector("#ConceptLinksCharts .tabs-content__itm.active .apexcharts-canvas svg")){
				const svg = document.querySelector("#ConceptLinksCharts .apexcharts-canvas svg")
				const conceptLinksPNG = await convertSVGtoImg({svg: svg})

				sections.push({type: "image", content: conceptLinksPNG, title: 'Концепты, упоминаемые вместе с объектом досье в отобранных материалах', name: 'concept_links' + user.id + '.png'})
			}

			if(document.querySelector("#ConceptLinksCharts .tabs-content__itm.active .link-itm-table")){
				let contentHtml = document.querySelector("#ConceptLinksCharts .tabs-content__itm.active .link-itm-table").outerHTML
				let relatedConcepts = document.createElement("DIV")
				relatedConcepts.innerHTML = contentHtml
				relatedConcepts.querySelectorAll('.item-with-menu__btn, .tooltip').forEach(obj => {
					obj.remove()
				})
				sections.push({type: "html", content: relatedConcepts.outerHTML, title: 'Объекты, упоминаемые вместе с объектом досье в отобранных материалах'})
			}

			if(document.querySelector("#ConceptLinksCharts .tabs-content__itm.active .tag-cloud-block")){
				//tagCloud
				const conceptsTagCloud = document.querySelector('#ConceptLinksCharts .tabs-content__itm.active .tag-cloud-block').innerHTML
				sections.push({type: "html", content: conceptsTagCloud, title: 'Объекты, упоминаемые вместе с объектом досье в отобранных материалах'})
			}

		}

		//docs
		const isConceptDocs = (document.querySelector('input[name="ConceptDocs"]'))? document.querySelector('input[name="ConceptDocs"]').checked : false
		if(isConceptDocs) {
			let docsContent = document.createElement('DIV')
			let docsContentParent = document.querySelector('#ConceptDocs')
			let docsHTML = ''
			docsContent.innerHTML = docsContentParent.innerHTML
			docsContent.querySelectorAll('.tooltip, .tabs-head, .detail-material-itm-content__top a').forEach(obj => {
				obj.remove()
			})
			docsContent.querySelectorAll('.detail-material-itm-content__info__date').forEach(obj => {//parse date
				if(obj.innerText.length > 0)
					obj.innerHTML = obj.querySelector('span:first-child').innerText + ' ' + obj.querySelector('span:last-child').innerText
			})

			if (docsContentParent.querySelector('.tabs-head__itm.active').innerText === 'Только заголовок') {
				docsContent.querySelectorAll('.detail-material-itm-content__text').forEach(obj => {
					obj.remove()
				})
			} else if (docsContentParent.querySelector('.tabs-head__itm.active').innerText === 'Заголовок + анонс') {
				docsContent.querySelectorAll('.detail-material-itm-content__text').forEach(obj => {
					obj.querySelector('.detail-material-itm-content__text__inner').innerText = (obj.innerText.length > 300) ? obj.innerText.substring(0, 300) + '...' : obj.innerText;
				})
			}

			docsHTML += docsContent.querySelector('.title-flex').innerHTML
			docsContent.querySelectorAll('.detail-material-itm').forEach(obj => {
				if (obj.querySelector('input[type="checkbox"]').checked) {
					docsHTML += obj.querySelector('.detail-material-itm-content').outerHTML
				}
			})
			sections.push({type: 'html', content: docsHTML})
		}
		//docs stat
		const isDocsStat = (document.querySelector('input[name="DocStat"]'))?  document.querySelector('input[name="DocStat"]').checked : false
		if(isDocsStat) {
			let docsStatContent = document.querySelector('#ConceptDocStat .detail-references__left').innerHTML
			const svgDocsStat = document.querySelector("#ConceptDocStat .apexcharts-canvas svg")
			const docsStatPNG = await convertSVGtoImg({svg: svgDocsStat})

			sections.push({type: 'html', content: docsStatContent})
			sections.push({type: 'image', content: docsStatPNG, name: 'docs_stat' + user.id + '.png'})
		}

		//sources
		const isConceptSources = (document.querySelector('input[name="ConceptSources"]'))? document.querySelector('input[name="ConceptSources"]').checked : false
		if(isConceptSources){
			sections.push({type: "html", content: document.querySelector('#ConceptSourcesTable').outerHTML, title: "Перечень источников"})
		}

		//sending report data
		if(sections.length > 0) {
			analyticsBACK.post('/reports',
				{
					sections: sections,
					concept: conceptId,
					conceptName: conceptMeta.name,
					report_type: 'dossier',
					user_id: user.id,
					link: pathName.pathname
				},
			)
				.then(resp => {
					if (resp.data.file) {
						setModalOpened(true)
						setReportDownloadLink(resp.data.file)
						// fileDownload(resp.data, `report${new Date().toLocaleDateString('ru')}.docx`);
					} else {
						console.error('bad request')
					}
					setFileCreating(false)
					setModalOpened(true)
				})
				.catch((resp) => {
					console.error('MAKING_REPORT_FAIL')
					setFileCreating('fail')
				})
		}else {

		}
		return true
	}

	return <main ref={main} className='main-content content visible'>
		<div className="query-detail-page">
			<div className="query-detail-section">
				<div className="query-detail-section__content"></div>
				<div className="query-detail-section__aside">
					<div className="small light ta-c">
						Включать в&nbsp;отчет
					</div>
				</div>
			</div>

			<ConceptMeta checkboxes={checkboxes} setCheckboxes={setCheckboxes} conceptMeta={conceptMeta}/>

			{types !== false
				? types !== "none"
					?<RememberList title="Типы связей:">
						<ul>
							{makeConnectionsTypes()}
						</ul>
					</RememberList>
					: ''
				:  <><Skeleton width="100px" height='43px' inline={true} style={{marginRight: '20px', marginBottom: '20px'}} /><Skeleton width="150px" height='43px' /></>
			}

			<ConnectionsGraph  checkboxes={checkboxes} setCheckboxes={setCheckboxes} links={filteredLinks} setLinks={updateLinks} updateTypes={updateTypes}/>
			{/*<ConceptInSearch conceptID={conceptId} checkboxes={checkboxes} setCheckboxes={setCheckboxes} conceptMeta={conceptMeta}/>*/}
			<RelatedConcepts conceptID={conceptId} checkboxes={checkboxes} setCheckboxes={setCheckboxes} conceptLinks={conceptLinks}/>
			<ConceptEvents  checkboxes={checkboxes} setCheckboxes={setCheckboxes} conceptID={conceptId}/>
			<ConceptInDocs checkboxes={checkboxes} setCheckboxes={setCheckboxes} conceptDocsLinks={conceptDocsLink} dataFetching={kbDocsCount === kbDocsTotal}/>
			<ConceptDocs
				defVars={{
					id: conceptId,
					limit: 5
				}}
				title="Материалы об объекте"
				query={CONCEPT_DOCS_PAGE}
				datesQuery={CONCEPT_DOCS_PAGE_DATES_FILTER}
				checkboxes={checkboxes}
				setCheckboxes={setCheckboxes}
				materialCheckboxes={materialCheckboxes}
				setMaterialCheckboxes={setMaterialCheckboxes}
				setDocsCountParent={(...param) => {}}
				setFullList={(...param) => {}}
			/>

			{conceptMeta &&
				<ConceptDocStat
					docsCount={kbDocsTotal}
					conceptID={conceptId}
					checkboxes={checkboxes}
					setCheckboxes={setCheckboxes}
					conceptMeta={conceptMeta}
				/>
			}

			<ConceptSources checkboxes={checkboxes} setCheckboxes={setCheckboxes} sources={sources}/>
		</div>

		<div className="detail-export">
			<div aria-disabled={(
				false//fileCreating
			)} className="btn detail-export__btn" onClick={() =>{
				makeReport()
				// if(!reportDownloadLink)
				// 	makeReport()
				// else
				// 	setModalOpened(true)
			}}>
				<HandySvg src={ico_doc} width={28} height={34}></HandySvg>
				Экспорт отчета в формате .docx
			</div>
			{fileCreating === 'fail' &&
				<p className='ta-c' style={{fontSize: "1.5rem"}}>Ошибка при создании отчета</p>
			}
			<Modal className="modal--export" opened={modalOpened} setOpened={setModalOpened}>
				<div className="modal-export-top">
					{reportDownloadLink &&
						<a className="btn" href={`${BACK_HOST}/public/${reportDownloadLink}`}  download={true}>
							<HandySvg src={ico_doc} width={28} height={34}></HandySvg>
							Скачать файл отчета
						</a>
					}
				</div>
				{/*<div className="modal-export-bot">*/}
				{/*	<div className="title ta-c c-main-dark">Отправить на email</div>*/}
				{/*	<form className="modal-export-form nice-form">*/}
				{/*		<label className="input-wrap">*/}
				{/*			<input type="text" name="login" placeholder=" "/>*/}
				{/*			<span className="input-wrap__label">Введите email</span>*/}
				{/*		</label>*/}
				{/*		<button className="btn small">Отправить</button>*/}
				{/*	</form>*/}
				{/*</div>*/}
			</Modal>
		</div>
	</main>

}

export default Dossier

