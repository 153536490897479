import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {analyticsBACK} from "../../ApiEndpoints";

export default function Decision(){
	const {secret, decision} = useParams();
	const [checking, setChecking] = useState(false);
	const [checkState, setCheckState] = useState('no');
	const [checkMessage, setCheckMessage] = useState('')

	useEffect(() => {
		if(secret){
			setChecking(true)
			var formData = new FormData();
			formData.append('secret', secret);
			formData.append('decision', decision);
			analyticsBACK.post('/checkRegister', formData)
				.then((resp) => {
					setCheckState('yes')
					setCheckMessage(resp.data.message)
				})
				.catch((resp) => {
					console.error('SECRET_CHECK_FAIL')
					setCheckState('fail')
					setCheckMessage(resp.response.data.message)
				})
				.finally(() => {
					setChecking(false)
				})
		}
	}, [secret]);

	return <main className={'main-content'}>
		<div className="content visible">
			<div className="title">
				{checking
					? <>Проверяем информацию <span style={{display: 'inline-block', verticalAlign: 'middle'}} className={"mini-loader"}></span></>
					: checkState === 'no'
						? <>Информация не подтверждена</>
						: checkState === 'yes'
							? <>Информация подтверждена</>
							: <>Произошла ошибка</>
				}
			</div>

			<p className="small light auth-page-desc">
				{checking
					? <>Для активации учетной записи необходимо принять решение</>
					:  checkState === `fail`
						? <>Произошла ошибка <br /> {checkMessage}</>
						: <>Данные учетной записи успешно прошли проверку, {checkMessage}</>
				}
			</p>
		</div>
	</main>
}