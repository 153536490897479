import React, {useState} from "react";
import Skeleton from "react-loading-skeleton";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {analyticsBACK} from "../../ApiEndpoints";
import {setStreamList} from "../../reducers/streamsSlice";

const SavedSteamTable = () => {
	const streams = useSelector(state => state.stream.list)
	const user = useSelector(state => state.user.value)
	const [deleting, setDeleting] = useState('')
	const dispatch = useDispatch()
	const tableHeader = () => {
		const header = [
			"Название ленты",
			"Дата\nсоздания",
			"Дата\nобновления",
			"Количество\nматериалов",
			"Удалить\nленту"
		]
		return <tr key='tableHead'>
			{header.map(itm => <th key={itm}>{itm}</th>)}
		</tr>
	}

	const removeStream = (stream) => {
		if(typeof stream === 'object'){
			setDeleting(stream.id)
			analyticsBACK.delete('/stream', {
				params: {
					streamID: stream.id,
					userID: user.id
				}
			})
				.then(resp => {
					if(resp.data)
						dispatch(setStreamList(resp.data))
					else
						dispatch(setStreamList('none'))

					setDeleting('')
				})
				.catch(resp => {
					console.error("REMOVING_STREAM_FAIL")
					setDeleting('')
				})
		}
	}

	const makeSkeleton = () => {
		return <tr key={`sk_1`}>
			<td>
				<p className='title c-main-dark'><Skeleton key={`fr_1`} width="250px" highlightColor='#412779' /></p>
			</td>
			<td>
				<Skeleton width="150px" />
				<span className='c-gray-bg'><Skeleton width="100px"/></span>
			</td>
			<td>
				<Skeleton width="150px" />
				<span className='c-gray-bg'><Skeleton width="100px"/></span>
			</td>
			<td>
				<Skeleton width='60px' />
			</td>
			<td>
			</td>
		</tr>
	}

	const toggleInformer = (stream, type) => {
		analyticsBACK.post('/stream/info_type', {
			stream, type, user_id: user.id
		})
			.then((resp) => {
				if(resp.data)
					dispatch(setStreamList(resp.data))
				else
					dispatch(setStreamList('none'))

				setDeleting('')
			})
			.catch(resp => {
				console.error("REMOVING_STREAM_FAIL")
				setDeleting('')
			})
	}

	const tableContent = () => {
		if(Array.isArray(streams)){
			return streams.map(stream => {
				let update = new Date(stream.updated_at)
				let create = new Date(stream.created_at)
				return <tr key={`row_${stream.id}`}>
					<td>
						<p>
							<Link key={stream.id} to={`/stream/content${stream.query}&from_saved=${stream.id}`} className="title c-main-dark">{stream.name}</Link>
							{stream.updated
								? <span className="badge info">новое</span>
								: ''
							}
						</p>
						<p>
							<label className="checkbox">
								<input
									name={stream.id + 'tg'}
									type="checkbox"
									value="true"
									checked={stream.telegram_info}
									onChange={() => {
										toggleInformer(stream.id, 'telegram')
									}}
								/>
								<span className='label_text'>Уведомлять в telegram</span>
								<span className="checkbox__check"></span>
							</label>

							<label className="checkbox">
								<input
									name={stream.id + 'email'}
									type="checkbox"
									value="true"
									checked={stream.email_info}
									onChange={() => {
										toggleInformer(stream.id, 'email')
									}}
								/>
								<span className='label_text'>Уведомлять по email</span>
								<span className="checkbox__check"></span>
							</label>
						</p>

					</td>
					<td>
						{create.toLocaleDateString('ru')}<br />
						<span className='c-gray-bg'>{create.toLocaleTimeString('ru')}</span>
					</td>
					<td>
						{update.toLocaleDateString('ru')}<br />
						<span className='c-gray-bg'>{update.toLocaleTimeString('ru')}</span>
					</td>
					<td>
						{stream.documents_count}<br />
						{stream.updated
							? <span className="c-red">{stream.new_count}</span>
							: ''
						}
					</td>
					<td>
						{deleting === stream.id
							? <Skeleton highlightColor="#F00" width="15px" height='15px' circle={true} />
							: <div onClick={() => removeStream(stream)} className='cross cross--text'> Удалить</div>
						}
					</td>
				</tr>
			})
		}else if(streams === 'none'){
			return <tr>
				<td>Нет сохраненных лент</td><td></td><td></td><td></td><td></td>
			</tr>
		}
	}

	if(streams === 'fail'){
		return <>
			<h6>Не удалось загрузить сохраненные ленты.</h6>
		</>
	}

	return <table className="stream-table">
		<thead>
		{tableHeader()}
		</thead>
		<tbody>
		{streams === false
			? makeSkeleton()
			: tableContent()
		}
		</tbody>
	</table>
}

export default SavedSteamTable