import React, {useEffect, useState} from "react";
import LoaderPage from "../../components/laders/LoaderPage";
import authLogo from "../../img/auth-logo.svg";
import authText from "../../img/auth-text.svg";
import {Link, useParams} from "react-router-dom";
import {analyticsBACK} from "../../ApiEndpoints";

const Final = () => {
	const {secret} = useParams();
	const [checking, setChecking] = useState(false);
	const [checkState, setCheckState] = useState('no');

	useEffect(() => {
		if(secret){
			setChecking(true)
			var formData = new FormData();
			formData.append('secret', secret);
			analyticsBACK.post('/checkRegister', formData)
				.then((resp) => {
					setCheckState('yes')
				})
				.catch((resp) => {
					console.error('SECRET_CHECK_FAIL')
				})
				.finally(() => {
					setChecking(false)
				})
		}
	}, [secret]);

	if(secret){
		return <div className="auth-page">
			<div className="content visible">
				<LoaderPage loading=""/>
				<div className="auth-page-content">
					<div className="auth-page-content__left">
						<Link to={"/"} ><img src={authLogo} alt="" className="auth-page-logo"/></Link>
					</div>

					<div className="auth-page-content__right">
						<Link to={"/"}><img src={authText} alt="" className="auth-page-text"/></Link>

						<div className="title">
							{checking
								? <>Проверяем информацию <span style={{display: 'inline-block', verticalAlign: 'middle'}} className={"mini-loader"}></span></>
								: checkState === 'no'
									? <>Информация не подтверждена</>
									: <>Информация подтверждена</>
							}
						</div>

						<p className="small light auth-page-desc">
							{!checking && checkState != 'no'
								? <>Данные вашей учетной записи успешно прошли проверку, вы можете <Link to={"/"}>авторизоваться</Link> на сайте.</>
								: <>Для активации вашей учетной записи, необходимо
									подтверждение емейла. Проверьте свою почту.</>
							}
						</p>
					</div>
				</div>
			</div>
		</div>
	}else{
		return <div className="auth-page">
			<div className="content visible">
				<LoaderPage loading=""/>
				<div className="auth-page-content">
					<div className="auth-page-content__left">
						<img src={authLogo} alt="" className="auth-page-logo"/>
					</div>

					<div className="auth-page-content__right">
						<img src={authText} alt="" className="auth-page-text"/>

						<div className="title">Подтверждение регистрации</div>

						<p className="small light auth-page-desc">Для активации вашей учетной записи, необходимо
							подтверждение емейла. Проверьте свою почту.</p>
					</div>
				</div>
			</div>
		</div>
	}
}

export default Final