import React from "react"
import {HandySvg} from 'handy-svg'
import {useEffect, useState} from "react"

import loader_bg from '../../ico/loader-bg.svg'
import {useCookies} from "react-cookie";

function LoaderPage({loading}) {
	const [state, setState] = useState(loading)
	const [cookies] = useCookies(['theme'])

	// useEffect(() => {
	// 	setState('loading')
	// }, [])

	useEffect(() => {
		if(loading){
			setState('loading')
		}else{
			setTimeout(() => {
				setState('')
			}, 500)
		}
	}, [loading])

	useEffect(() => {//select theme
		document.querySelector('body').classList.remove("theme-dark")
		document.querySelector('body').classList.remove('theme-light')
		document.querySelector('body').classList.add('theme-' + ((cookies.theme)? cookies.theme : 'light'))
	}, [cookies.theme])

	return <div className={"loader " + state}>
		<div className="loader-ico">
			<div className="loader-ico-bg">
				<HandySvg src={loader_bg} width={130}></HandySvg>
			</div>
			<div className="loader-ico-dots">
				<div className="loader-dot dot-active"></div>
				<div className="loader-dot"></div>
				<div className="loader-dot"></div>
				<div className="loader-dot"></div>
				<div className="loader-dot"></div>
				<div className="loader-dot"></div>
				<div className="loader-dot"></div>
				<div className="loader-dot"></div>
				<div className="loader-dot"></div>
				<div className="loader-dot"></div>
			</div>
		</div>
		{/*<div className="loader-text title c-main-dark">Идет обработка запроса</div>*/}
	</div>
}

export default LoaderPage